// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyArjsE_EIgfNp5P0p8gQyzbWtT_WnpB-gY",
    authDomain: "ela---stage.firebaseapp.com",
    projectId: "ela---stage",
    storageBucket: "ela---stage.appspot.com",
    messagingSenderId: "873705956253",
    appId: "1:873705956253:web:a0d5cd2fc15b9eee3cc574",
    measurementId: "G-GJEDRMRD5G",
    vapidKey: "BMLv0gR1-HAsJCsbP766mOAFZzXtQnsfV2HZGxVZ5xrir8eUUxivnm5zwbj07-PVSLxb7dAq3VHVikK_8NQSHSQ"
},
};


export const baseUrl = 'https://apistageela.12skiestech.com/api/'; //  publish api 



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
